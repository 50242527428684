
<template>
  <div>
    <br />
    <div class="col-sm-12 text-right">
      <h1>Catálogos</h1>
    </div>
    <br />
    <DataGridV3 :configuracion="dataGridConfig" :data="dataSourceGrid" :seleccionar="seleccionar" ref="gridCatalogos"  @ready="cargarAcciones">
      <div class="row">
        <div class="col-sm-auto">
          <button class="btn btn-info" @click="cargar()"> <i class='fa fa-refresh'></i> Recargar</button>
        </div>
        <div class="col-sm-auto" v-if="$auth.can('garantias_app','catalogos_crear')">
          <button  class="btn btn-primary" @click="crear"><i class='fa fa-plus-circle'></i> Nuevo</button>
        </div>
      </div>

    </DataGridV3>


    <formulario-catalogo v-if="openModalForm" :catalogo="itemSeleccionado.codigo" @actualizar="cargar" @cancelar="calcelar_seleccion"></formulario-catalogo>


    <Modal v-if="openModalEliminar" :options="{width: '30vw',type:'error'}" @close="openModalEliminar = false">
      <div class="title">Eliminar: {{ itemSeleccionado.nombre }} </div>
      <div class="body">
        <div class="row">
          <div class="col-sm-12 text-center">
            Eliminarás el catálogo{{ itemSeleccionado.nombre }} <br/>¿Deseas continuar?
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row justify-content-end">
          <div class="row justify-content-end">
            <div class="col-auto"><button class="btn btn-primary" @click="eliminar"><i class="fa fa-check-square mr-sm-1"></i>Continuar</button></div>
            <div class="col-auto"><button class="btn btn-outline-secondary" @click="calcelar_seleccion"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
          </div>
        </div>
      </div>
    </Modal>

  </div>
</template>

<script>

import Modal from "@/components/Modal.vue";
import DataGridV3 from "@/components/DataGridV3.vue";
import catalogosApi from "@/apps/garantias_app/api/catalogos/catalogos.api";

import FormularioCatalogo from "@/apps/garantias_app/pages/catalogos/FormularioCatalogo.vue";

export default {
  name: "Catalogos",
  data(){
    return {
      openModalForm: false,
      openModalEliminar: false,
      dataGridConfig: {
        name: 'catalogos',
        cols: {
          acciones: '',
          nombre: 'Nombre',
          codigo: 'Código',
          estatus: 'Estatus',
        }
        ,filters: {
          cols: {
            external_id: 'ID externo',
            nombre: 'Nombre',
          }
        }
        ,paginator: {
          pagina_actual: 1
          ,total_registros: 1
          ,registros_por_pagina: 20
        }
        ,mutators: {
          acciones: function(val,row,vue) {
            let acciones = "<div class='text-center'>";
            if (vue.$auth.can('garantias_app','catalogos_editar'))
              acciones += "<i data-action='editar' data-opcion='"+vue.$helper.toJson(row)+"' class='btn-sm btn-primary btn-acciones fa fa-edit mr-sm-2'></i>";
            /**if (vue.$auth.can('garantias_app','catalogos_eliminar'))
              acciones += "<i data-action='pre_eliminar' data-opcion='"+vue.$helper.toJson(row)+"' class='btn-sm btn-dark btn-acciones fa fa-trash'></i>";**/

            return acciones+"</div>";
          },
          estatus: function (val,row,vue){
            const estatus = !val ? 'Inactivo' : 'Activo'
              return "<span>"+ estatus +"</span>"
          }
        },
        show_search: true,
        search: ['nombre'],
        selector: false,
      },
      seleccionar: false,
      dataSourceGrid: [],
      accionesCargadas: false,
      itemSeleccionado: {
        id: null,
        nombre: null,
        codigo: null,
        estatus: null
      }
    }

  },
  components: {
    FormularioCatalogo,
    DataGridV3,
    Modal
  },
  computed: {

  },
  mounted() {
    this.cargar()
  },
  methods: {
    cargarAcciones() {
      let btns = document.querySelectorAll('.btn-acciones');
      btns.forEach(btn => {
        btn.removeEventListener('click', this.ejecutarAccionBtn )
        btn.addEventListener('click', this.ejecutarAccionBtn)
      })
    },
    ejecutarAccionBtn(e){
      let seleccion  = this.$helper.fromJson(e.target.dataset.opcion);
      if (seleccion.id !== this.itemSeleccionado.id) {
        let accion = e.target.dataset.action;
        this.itemSeleccionado = {...seleccion}
        this[accion](seleccion);
      }
    }
    ,calcelar_seleccion(){
      this.openModalForm = false;
      this.openModalEliminar = false;

      this.itemSeleccionado = {
        id: null,
        nombre: null,
        codigo: null,
        estatus: null
      };
    },
    crear(){
      this.calcelar_seleccion()
      this.openModalForm = true;
    },
    editar(){
      this.openModalForm = true;
    },
    pre_eliminar(){
      this.openModalEliminar = true;
    },
    async eliminar(){
      this.openModalEliminar = false;
      await catalogosApi.eliminar(this.itemSeleccionado.id).then(() => {
      this.$helper.showMessage('Eliminar catálogo','Catálogo eliminado exitosamente.', 'success', 'alert')
      this.cargar()
      })
    },

    async cargar() {
      this.calcelar_seleccion()
      let dataSource = await catalogosApi.index(false)
      this.dataSourceGrid = dataSource.data

      this.calcelar_seleccion()
    }
  }

}
</script>


<style scoped lang="scss">

</style>